<template style="background-image: url('../assets/images/background.jpg')">
  <b-container class="icon-sidebar-nav" fluid>
    <b-row>
<!--       <main-sidebar :items="sidebarItems" hide-logo-text/>-->
    <main-sidebar :items="sidebarItems" v-if="sessionId !== 2" hide-logo-text/>
      <main class="main-content col" style="background-image: url('../assets/images/background.jpg')">
        <main-navbar/>
        <slot/>
      </main>
    </b-row>
    <CreateModal ref="modal" @created="goToTicket" floating/>
  </b-container>


</template>

<script>
import {adminMenu} from '@/data/sidebar-nav-items';

// Main layout components
import MainNavbar from '@/components/layout/MainNavbar/MainNavbar.vue';
import MainSidebar from '@/components/layout/MainSidebar/MainSidebar.vue';
import MainFooter from '@/components/layout/MainFooter/MainFooter.vue';
import {getDataStorage} from '@/helpers/localStorage';
import CreateModal from '@/views/dashboard/support/components/create-modal';
import {mapGetters, mapState} from 'vuex';
import Floatchat from '@/views/dashboard/support/components/Floatchat';
import {isArray, userCan} from '@/utils';
import {UserRole} from '@/utils/auth.roles';
import {checkSubUserPermission} from "@/utils/prototypes";

export default {
  name: 'Default',
  components: {
    CreateModal,
    MainNavbar,
    MainSidebar,
    MainFooter,
  },
  data: () => ({
    sessionId: null,
    userRoles: [],
    sidebarItems: [],
    activeModal: false,
    category: null,
    redirectToTicket: true,
    timeoutInMiliseconds: 82800000, // 30 minutos   -- 1800000 milisecond
    timeoutId: 0,
    hasSubdealers: false,
  }),
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapState('buySale', ['information']),
  },
  mounted() {
    this.$store.dispatch('geo/loadCountries');
    const id = this.$store.getters.getUserId;
    if (id) {
      this.sessionId = id;
      this.$socket.io.opts.query = {
        token: getDataStorage('admin-token')
      };
      this.$socket.open();
    }
    if (this.user !== null) {
      this.getRol();
    }
    this.$eventBus.$on('openTicket', data => {
      if (data.category_id) {
        let category_id = data.category_id;
        const category = this.$store.getters['support/getCategoryById'](category_id);
        this.$refs.modal.setCategory(category, data.order_id, data.model);
      }
      this.redirectToTicket = data.redirect;
      if (data.file) {
        this.$refs.modal.showFileInput = true;
      }
      if (data.messageDefault) {
        this.$refs.modal.model.initialMessage = data.messageDefault;
      }
      if (!!this.$refs.modal) {
        this.$refs.modal.reset();
        this.$refs.modal.open(data.mode);
      }
    });
    this.$eventBus.$on('closeModalTicket', data => {
      this.$refs.modal.close();
    })
    this.detectInactivity();
  },
  methods: {
    async getRol() {
      await this.$store.dispatch('support/loadCategoryTypes');
      await this.$store.dispatch('buySale/loadInformation');
      this.userRoles = JSON.parse(getDataStorage('admin-type'));
      if (userCan([UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer])) {
        this.hasSubdealers = this.information.haveChildren;
      } else {
        this.hasSubdealers = true
      }
      this.processRol().then();
    },
    filterItem(item) {
      if (item.roles === undefined || item.roles.some(rol => this.userRoles.includes(rol))) {
        if (this.$store.state.user.subUser && item.subUserPermission) {
          return checkSubUserPermission(item.subUserPermission)
        }
        if (item.hasDealers) {
          return this.hasSubdealers;
        }
        if (item.items) {
          item.items = item.items.filter(el => {
            return this.filterItem(el);
          });
        }
        return true;
      } else {
        return false;
      }
    },
    async processRol() {
      const canShowMenu = (Number(getDataStorage('admin-term')));
      this.sidebarItems = JSON.parse(JSON.stringify(adminMenu))
        .filter(el => {
          if (!el.canViewWithoutTerms && canShowMenu) {
            return this.filterItem(el);
          }
          return false;
        });
    },
    goToTicket(e) {
      if (this.redirectToTicket) {
        this.$router.push({
          name: 'support-detail',
          params: {
            id: e.id
          }
        });
      } else {
        this.$eventBus.$emit('ticketCreated', e);
      }
    },
    async detectInactivity() {
      document.addEventListener('keypress', this.resetTimer, false);
      document.addEventListener('click', this.resetTimer, false);
      this.startTimer();
    },
    resetTimer() {
      window.clearTimeout(this.timeoutId);
      this.startTimer();
    },
    startTimer() {
      this.timeoutId = window.setTimeout(this.doInactive, this.timeoutInMiliseconds);
    },
    doInactive() {
      this.$socket.close();
      try {
        this.$swal({
          confirmButtonColor: '#d14343',
          cancelButtonColor: '#000000',
          showConfirmButton: false,
          title: 'Session Ended',
          html: 'Your session has been closed due to inactivity',
          icon: 'success',
        });
        localStorage.clear();
        this.$store.commit('setUser', null);
        this.$router.push({name: 'login'}).then().catch();
      } catch (e) {
        this.logout();
      }
    }
  }
};
</script>


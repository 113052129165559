import Vue from 'vue';
import {userCan} from '@/utils';
import {UserRole} from '@/utils/auth.roles';
import {mapGetters, mapState} from 'vuex';
import {apiUrl, get, post} from '@/services/api';
import {getDataStorage} from '@/helpers/localStorage';
import {randomPastelColor} from "@/utils/colors";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default Vue.extend({
  name: 'SupportChat',
  mixins: [show_alert_mixin],
  props: {
    showHead: {default: true},
    showAsignMe: {default: false},
    supportUsers: {default: []},
    order: {
      default: null
    },
    viewed: {
      default: null
    }
  },
  data: () => ({
    apiUrl,
    support_id: null,
    support: null,
    me: null,
    other: null,
    subTitle: '',
    model: {
      text: '',
      file: null,
      fileName: null,
      imgPreview: null,
    },
    mimePermitted: [
      'application/pdf',
      'image/jpeg',
      'image/png',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'video/mp4',
      'video/webm',
      'video/ogg',
      'text/csv',
      'text/xml',
      'application/xml',
      'text/plain',
      'application/vnd.ms-excel',
      'text/x-csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'audio/x-mod',
      'application/msword',
      'application/vnd.oasis.opendocument.text',
      'application/rtf'
    ],
    imageTypes: [
      'image/jpeg',
      'image/png',
    ],
    videoTypes: [
      'video/mp4',
      'video/webm',
      'video/ogg'
    ],
    urlTypes: [
      'http',
      'https'
    ],
    fileList: [],
    index: null,
    sending: false,
    editorConfig: {
      readOnly: false,
      toolbarGroups: [
        {
          name: 'document',
          groups: ['mode', 'document', 'doctools']
        },
        {
          name: 'clipboard',
          groups: ['clipboard', 'undo']
        },
        {
          name: 'editing',
          groups: ['find', 'selection', 'spellchecker', 'editing']
        },
        {
          name: 'forms',
          groups: ['forms']
        },
        {
          name: 'basicstyles',
          groups: ['basicstyles', 'cleanup']
        },
        {
          name: 'paragraph',
          groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']
        },
        {
          name: 'links',
          groups: ['links']
        },
        {
          name: 'insert',
          groups: ['insert']
        },
        {
          name: 'styles',
          groups: ['styles']
        },
        {
          name: 'colors',
          groups: ['colors']
        },
        {
          name: 'tools',
          groups: ['tools']
        },
        {
          name: 'others',
          groups: ['others']
        },
        {
          name: 'about',
          groups: ['about']
        },
      ],
      removeButtons: 'Source,ExportPdf,NewPage,Save,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Button,ImageButton,HiddenField,Select,Image,Flash,Table,HorizontalRule,SpecialChar,PageBreak,Iframe,Smiley,Anchor,Styles,Format,FontSize,Font,BGColor,ShowBlocks,About,BidiLtr,BidiRtl,Language,CreateDiv,Blockquote,CopyFormatting,RemoveFormat'
    },
    errorMessage: false,
    userColors: {}
  }),
  computed: {
    ...mapGetters({
      userId: 'getUserId',
    }),
    ...mapState('support', {
      supportStatus: state => state.ticketStatusTypes,
      supportCats: state => state.ticketCategoryTypes
    }),
    images() {
      return this.fileList.filter(el => this.imageTypes.includes(el.type))
        .map(el => `${el.s3url}`);
    },
  },
  methods: {
    clear() {
      this.$socket.emit('leaveRoom', `chat:${this.support_id}`);
      this.sockets.unsubscribe(`newMsgChat:${this.support_id}`);
      this.sockets.unsubscribe(`supportUpdate:${this.support_id}`);
      this.support = null;
      this.fileList = [];
    },
    loadData(showLoader = true) {
      if (showLoader)
        this.$store.commit('loaderManager', true);
      get(`support/view/${this.support_id}`, null, true)
        .then(async (response) => {
          this.support = response.data;
          this.editorConfig.readOnly = this.support.supportTicketStatusId === 3;
          this.setSubtitle();
          if (!await userCan(UserRole.Support)) {
            this.me = this.support.clientUser;
          } else {
            this.me = this.support.staffUser;
          }
          this.support.supportTicketMessages.forEach((el) => {
            if (el.extraData !== null) {
              el.extraData = JSON.parse(el.extraData);
              this.fileList.push(...el.extraData);
            }
          });
          this.$nextTick(() => this.scrollBtn());
          this.$emit('categoryId', this.support.supportTicketCatId);
          this.$emit('loaded', this.support);
        })
        .finally(() => this.$store.commit('loaderManager', false));
    },
    isMe(message) {
      return parseInt(this.userId) === message.user.id;
    },
    sendMsg() {
      const origin = this.$route.name
      this.errorMessage = false
      if ((this.model.text !== '' || this.model.file !== null) && this.model.text.length <= 2500) {
        this.sending = true;
        const formData = new FormData();
        formData.append('text', this.model.text);
        formData.append('file', this.model.file);
        formData.append('origin', origin);
        if (origin === 'order-detail') {
          formData.append('viewed', JSON.stringify(this.viewed))
        }
        post(`support-message/create/${this.support_id}`, formData, true)
          .then(() => {
            this.model = {
              text: '',
              file: null,
              imgPreview: null,
            };
          })
          .finally(() => this.sending = false);
      } else {
        this.errorMessage = true
      }
    },
    processFile(e) {
      this.model.imgPreview = null
      const file = e.target.files[0];
      this.$refs.file.type = 'text';
      this.$refs.file.type = 'file';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (el) => {
        if (el.total <= 1024 * 1024 * 20) {   // 20 megas es el limite
          if (this.mimePermitted.includes(file.type)) {
            this.$nextTick(() => {
              this.$refs.file.value = null;
              this.$refs.form_send_message.reset();
            });
            this.model.file = file;
            this.model.fileName = file.name;
            if (this.imageTypes.includes(file.type)) {
              this.model.imgPreview = reader.result;
            }
          } else {
            this.showAlertNotification(this.$t('form.file_type_error').toString(), 'error')
          }
        } else {
          this.showAlertNotification(this.$t('form.file_size_error').toString(), 'error')
        }
      };
    },
    startWatchMsg() {
      this.$socket.emit('joinRoom', `chat:${this.support_id}`);
      this.sockets.subscribe(`newMsgChat:${this.support_id}`, (message) => {
        if (message.extraData !== null) {
          this.$emit('loadFiles');
          message.extraData = JSON.parse(message.extraData);
          this.fileList.push(...message.extraData);
        }
        this.support.supportTicketMessages.push(message);
        this.$nextTick(() => this.scrollBtn());
      });
      this.sockets.subscribe(`supportUpdate:${this.support_id}`, () => {
        this.loadData();
      });
    },
    setSubtitle() {
      if (userCan([UserRole.Dealer, UserRole.Distributor, UserRole.SubDealer])) {
        if (this.support.staffUser == null) {
          this.subTitle = this.$t('support.await_agent');
        }
      } else if (userCan([UserRole.Support, UserRole.Admin, UserRole.Calibrator])) {
        if (this.support.staffUser == null) {
          this.subTitle = this.$t('support.assign_me');
        }
      }
    },
    scrollBtn() {
      if (this.$el.attributes) {
        const container = this.$el.querySelector('#chat-stream');
        container.scrollTop = container.scrollHeight;
      }
    },
    setIndex(file) {
      this.index = this.images.findIndex(el => el === `${file.s3url}`);
    },
    isImage(file) {
      return this.imageTypes.includes(file.type);
    },
    isVideo(file) {
      return this.videoTypes.includes(file.type);
    },
    isUrl(text){
      return text.includes('https')|| text.includes('http')|| text.includes('www');
    },
    loadSupport(support_id) {
      this.clear();
      this.support_id = support_id;
      this.loadData(false);
      this.startWatchMsg();
    },
    async assignMe() {
      let user = getDataStorage('admin-id');
      post(`support/assign-agent/${this.support.id}`, {
        staffUserId: user
      })
        .then(({data}) => {
          this.support = data;
        });
    },
    sendToTvs() {
      get(`support/send-to-tvs/${this.support_id}`, null, true)
        .then()
        .catch();
    },
    sendToDistributor() {
      get(`support/send-to-distributor/${this.support_id}`, null, true)
        .then()
        .catch();
    },
    closeTocket() {
      post(`support/update/${this.support_id}`, {supportTicketStatusId: 3})
        .then(({data}) => {
          this.support = data;
        });
    },
    openTicket() {
      post(`support/update/${this.support_id}`, {supportTicketStatusId: 2})
        .then(({data}) => {
          this.editorConfig.readOnly = false;
          this.support = data;
          this.$forceUpdate()
        });
    },
    checkOwner() {
      return ((Number(this.support.ownerId) === this.$store.getters['getUserId']) || this.$userCan('administrator') ||
        (this.$userCan(['calibrator', 'support']) && this.support.staffUserId === this.$store.getters.getUserId));
    },
    getUserColor(message) {
      if (!this.userColors[message.user.id]) {
        this.userColors[message.user.id] = randomPastelColor()
      }
      return this.userColors[message.user.id];
    },
    changeColor(userId) {
      this.userColors[userId] = randomPastelColor();
      this.$forceUpdate()
    }
  },
  watch: {
    support() {
      this.$emit('on-load', this.support);
    },
  },
  destroyed() {
    this.$socket.emit('leaveRoom', `chat:${this.support_id}`);
    this.sockets.unsubscribe(`newMsgChat:${this.support_id}`);
    this.sockets.unsubscribe(`supportUpdate:${this.support_id}`);
  },
});

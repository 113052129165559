import {UserRole} from '@/utils/auth.roles';

export const adminMenu = [
  {
    title: 'PERSONAL',

    canViewWithoutTerms: true,
    canViewWithoutCompany: true,
    items: [
      {
        title: 'Information',
        roles: [UserRole.Admin, UserRole.Calibrator, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
        htmlBefore: '<i class="material-icons">account_circle</i>',
        to: {
          name: 'required-information',
        },
      },
    ],
  },
  {
    title: 'PERSONAL',
    canViewWithoutTerms: false,
    canViewWithoutCompany: false,
    items: [
      {
        title: 'Profile',
        subUserPermission: 'profile',
        roles: [UserRole.Admin, UserRole.Calibrator, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
        htmlBefore: '<i class="material-icons">account_circle</i>',
        to: {
          name: 'profile',
        },
      },
    ],
  },
  {
    title: 'MENU',
    canViewWithoutTerms: false,
    canViewWithoutCompany: false,
    items: [
      {
        title: 'User Management',
        subUserPermission: 'user_management',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
        htmlBefore: '<i class="material-icons">people</i>',
        count: 1,
        to: {
          name: 'user',
        },
      },
      {
        title: 'Dashboard',
        subUserPermission: 'dashboard',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
        htmlBefore: '<i class="material-icons">bar_chart</i>',
        to: {
          name: 'dashboard',
        },
      },
      {
        title: 'Order software',
        subUserPermission: 'order_software',
        roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Support],
        htmlBefore: '<i class="material-icons">computer</i>',
        to: {
          name: 'order-software',
        },
      },
      {
        title: 'Credits',
        subUserPermission: 'credits',
        roles: [UserRole.Admin, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
        htmlBefore: '<i class="material-icons">shopping_cart</i>',
        to: {
          name: 'credit-dashboard'
        }
      },
      {
        title: 'Support',
        subUserPermission: 'support',
        roles: [UserRole.Admin, UserRole.Calibrator, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
        htmlBefore: '<i class="material-icons">support_agent</i>',
        count: 1,
        to: {
          name: 'support',
        },
      },
      {
        title: 'Sales Analytics',
        subUserPermission: 'analytic',
        roles: [UserRole.Admin],
        htmlBefore: '<i class="material-icons">euro</i>',
        count: 1,
        to: {
          name: 'reports-credit',
        },
      },
      {
        title: 'New products',
        roles: [UserRole.Admin],
        htmlBefore: '<i class="material-icons">production_quantity_limits</i>',
        // htmlAfter: '<span style="margin-left: 1em;" id="ProductCounter" class="badge badge-danger">12</span>',
        count: 1,
        to: {
          name: 'newproducts',
        },
      },
      {
        title: 'Coupon',
        subUserPermission: 'coupon',
        roles: [UserRole.Admin, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
        htmlBefore: '<i class="material-icons">card_giftcard</i>',
        to: {
          name: 'coupon',
        },
      },
      {
        title: 'Orders',
        roles: [UserRole.Support],
        htmlBefore: '<i class="material-icons">list_alt</i>',
        items: [],
        to: {
          name: 'dashboard'
        },
      },
      {
        title: 'Downloads',
        subUserPermission: 'downloads',
        roles: [UserRole.Admin, UserRole.Calibrator, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
        htmlBefore: '<i class="material-icons">cloud_download</i>',
        to: {
          name: 'downloads',
        },
      },
      {
        title: 'Feedbacks',
        subUserPermission: 'feedbacks',
        roles: [UserRole.Admin, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
        htmlBefore: '<i class="material-icons">thumb_up_alt</i>',
        to: {
          name: 'feedback',
        },
      },
      {
        title: 'News',
        subUserPermission: 'news',
        roles: [UserRole.Admin, UserRole.Dealer, UserRole.SubDealer, UserRole.Distributor],
        htmlBefore: '<i class="material-icons">article</i>',
        to: {
          name: 'news',
        },
      }, {
        title: 'Help',
        subUserPermission: 'faqs',
        roles: [UserRole.Dealer, UserRole.SubDealer, UserRole.Distributor],
        htmlBefore: '<i class="material-icons">contact_support</i>',
        to: {
          name: 'faq',
        },
      },
    ],
  },
  {
    title: 'Database management ',
    roles: [UserRole.Admin],
    canViewWithoutTerms: false,
    canViewWithoutCompany: false,
    items: [
      {
        title: 'File Server',
        htmlBefore: '<i class="material-icons">tune</i>',
        to: {
          name: 'index-tuned-file',
        },
      },
      {
        title: 'Vehicles Database',
        htmlBefore: '<i class="material-icons">directions_car</i>',
        to: {
          name: 'database',
        },
      },
      {
        title: 'Price Config',
        htmlBefore: '<i class="material-icons">price_change</i>',
        to: {
          name: 'index-vehicles-price-config',
        },
      },
      {
        title: 'Vin Search Vehicles',
        htmlBefore: '<i class="material-icons">search</i>',
        to: {
          name: 'vin-logger',
        },
      },

      {
        title: 'Country Management',
        htmlBefore: '<i class="material-icons">flag</i>',
        to: {
          name: 'software-pricing',
        },
      },
    ],
  },
];

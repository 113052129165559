var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.active)?_c('div',{class:{'fixed-right': _vm.floating}},[_c('b-card',{attrs:{"body-class":"pt-1"}},[_c('b-card-header',{staticClass:"px-0"},[(_vm.floating)?_c('i',{staticClass:"material-icons pull-right",on:{"click":_vm.close}},[_vm._v("close")]):_vm._e(),_c('h5',[_vm._v(_vm._s(_vm.$t('support.new_ticket')))])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[(_vm.showCatSelect)?_c('ValidationProvider',{attrs:{"name":_vm.$t('support.category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('support.category')))]),_c('b-select',{model:{value:(_vm.model.supportTicketCatId),callback:function ($$v) {_vm.$set(_vm.model, "supportTicketCatId", $$v)},expression:"model.supportTicketCatId"}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t('form.select')))]),_vm._l((_vm.categories),function(cat){return _c('option',{domProps:{"value":cat.id}},[_vm._v(_vm._s(cat.name))])})],2),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('support.title_form'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('support.title_form')))]),_c('b-form-input',{attrs:{"placeholder":"Enter title"},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}),(_vm.mustSelectUserTo)?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("User")]),_c('b-select',{model:{value:(_vm.model.clientUserId),callback:function ($$v) {_vm.$set(_vm.model, "clientUserId", $$v)},expression:"model.clientUserId"}},[_c('option',{domProps:{"value":null}},[_vm._v("Select user")]),_vm._l((_vm.subUsers),function(user){return _c('option',{domProps:{"value":user.id}},[_vm._v(_vm._s(user.fullName))])})],2),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"rules":"vin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("VIN (optional)")]),_c('b-input',{staticStyle:{"text-transform":"uppercase"},model:{value:(_vm.model.vin),callback:function ($$v) {_vm.$set(_vm.model, "vin", $$v)},expression:"model.vin"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(("vue_validator." + (errors[0])))))]):_vm._e()],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('support.message'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t('support.message')))]),_c('b-textarea',{attrs:{"placeholder":"Describe your requirement","value":"","rows":"7"},model:{value:(_vm.model.initialMessage),callback:function ($$v) {_vm.$set(_vm.model, "initialMessage", $$v)},expression:"model.initialMessage"}}),(errors.length)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e(),_c('p',{staticClass:"text-danger",staticStyle:{"display":"none"},attrs:{"id":"error_length"}},[_vm._v("Maximum length is 2500 characters")])],1)]}}],null,true)}),(_vm.showFileInput)?_c('div',[(_vm.fileName != '')?_c('label',{staticClass:"form-control"},[_vm._v(_vm._s(_vm.fileName))]):_vm._e(),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file"},on:{"change":_vm.processFile}}),_c('b-button',{on:{"click":function($event){return _vm.$refs.fileInput.click()}}},[_c('i',{staticClass:"fa fa-file",attrs:{"aria-hidden":"true"}}),_vm._v(" Attach file ")])],1):_vm._e(),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t('form.submit')))])],1)],1)]}}],null,false,90269570)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div :class="{'fixed-right': floating}" v-if="active">
    <b-card body-class="pt-1">
      <b-card-header class="px-0">
        <i class="material-icons pull-right" @click="close" v-if="floating">close</i>
        <h5>{{ $t('support.new_ticket') }}</h5>
      </b-card-header>
      <ValidationObserver v-slot="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(submit)">
          <ValidationProvider v-slot="{errors}" :name="$t('support.category')" rules="required" v-if="showCatSelect">
            <div class="form-group">
              <label>{{ $t('support.category') }}</label>
              <b-select v-model="model.supportTicketCatId">
                <option :value="null">{{ $t('form.select') }}</option>
                <option v-for="cat of categories" :value="cat.id">{{ cat.name }}</option>
              </b-select>
              <small class="text-danger" v-if="errors.length">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider v-slot="{errors}" :name="$t('support.title_form')" rules="required">
            <div class="form-group">
              <label>{{ $t('support.title_form') }}</label>
              <b-form-input v-model="model.title" placeholder="Enter title"></b-form-input>
              <small class="text-danger" v-if="errors.length">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <validation-provider v-if="mustSelectUserTo" rules="required" v-slot="{errors}">
            <div class="form-group">
              <label>User</label>
              <b-select v-model="model.clientUserId">
                <option :value="null">Select user</option>
                <option v-for="user of subUsers" :value="user.id">{{ user.fullName }}</option>
              </b-select>
              <small class="text-danger" v-if="errors.length">{{ errors[0] }}</small>
            </div>
          </validation-provider>
          <validation-provider rules="vin" v-slot="{errors}">
            <div class="form-group">
              <label>VIN (optional)</label>
              <b-input v-model="model.vin" style="text-transform: uppercase"/>
              <small class="text-danger" v-if="errors.length">{{ $t(`vue_validator.${errors[0]}`) }}</small>
            </div>
          </validation-provider>
          <ValidationProvider v-slot="{errors}" :name="$t('support.message')" rules="required">
            <div class="form-group">
              <label>{{ $t('support.message') }}</label>

              <b-textarea placeholder="Describe your requirement" value="" v-model="model.initialMessage" rows="7"/>
              <small class="text-danger" v-if="errors.length">{{ errors[0] }}</small>
              <p id="error_length" class="text-danger" style="display: none">Maximum length is 2500 characters</p>
            </div>
          </ValidationProvider>
          <div v-if="showFileInput">
            <label class="form-control" v-if="fileName != ''">{{ fileName }}</label>
            <input type="file" @change="processFile" style="display: none" ref="fileInput">
            <b-button @click="$refs.fileInput.click()"><i class="fa fa-file" aria-hidden="true"></i> Attach file
            </b-button>
          </div>
          <div class="text-right">
            <b-button variant="primary" type="submit">{{ $t('form.submit') }}</b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>

import {post} from '@/services/api';
import {serializeToDataForm} from '@/utils';
import {mapState} from 'vuex';
import {decrypt} from "@/helpers/encrypt";
import show_alert_mixin from "@/utils/show_alert_mixin";

export default {
  name: 'create-modal',
  mixins: [show_alert_mixin],
  props: {
    floating: {
      type: Boolean,
      default: false
    },
    transfer: {
      default: null
    }
  },
  data: () => ({
    model: {
      clientUserId: null,
      staffUserId: null,
      ownerId: null,
      supportTicketCatId: null,
      initialMessage: '',
      questionDep: null,
      orderId: null,
      orderModel: null,
      file: null,
      title: null,
    },
    category: null,
    active: false,
    showFileInput: false,
    showCatSelect: true,
    fileName: '',
    mustSelectUserTo: false
  }),
  computed: {
    ...mapState('buySale', ['subUsers']),
    categories: function () {
      return this.$store.state.support.ticketCategoryTypes.filter(el => !el.requireOrder);
    }
  },
  methods: {
    submit: function () {
      if (this.model.initialMessage !== '' && this.model.initialMessage !== null && this.model.initialMessage.length <= 2500) {
        document.getElementById('error_length').style.display = 'none';
        if (this.model.vin !== undefined && this.model.vin !== null) {
          if (this.model.vin.length <= 20) {
            this.processTicket()
          } else {
            this.showAlertNotification('VIN, maximum length 20')
          }
        } else {
          this.processTicket()
        }
      } else {
        document.getElementById('error_length').style.display = 'block';
      }
    },
    processTicket() {
      const origin = this.$route.name
      this.model.questionDep = 'TECHNICAL';
      this.$store.commit('loaderManager', true);
      this.model.origin = origin;
      if (this.model.origin === 'order-detail') {
        this.model.viewed = this.processViewedInformation()
      }
      const postData = serializeToDataForm(this.model);
      post('support', postData, true)
        .then((resp) => {
          this.model = {
            supportTicketCatId: null,
            initialMessage: '',
            questionDep: null,
            orderId: null,
            orderModel: null, //0 = custom request | 1 order software
            file: null,
          };
          this.$emit('created', resp.data);
          this.close();
        })
        .catch()
        .finally(() => this.$store.commit('loaderManager', false));
    },
    close: function () {
      this.active = false;
      this.$emit('closeModal');
    },
    open: function (mode = 'client') {
      this.active = true;
      this.mustSelectUserTo = mode !== 'client';
      this.$store.dispatch('buySale/loadUserList');
    },
    reset() {
      this.model = {
        clientUserId: null,
        supportTicketCatId: null,
        initialMessage: '',
        questionDep: null,
        orderId: null,
        orderModel: null, //0 = custom request | 1 order software
        file: null,
      };
      this.showCatSelect = true;
    },
    setCategory(data, order_id = null, orderModel = null) {
      this.category = data;
      this.model.orderModel = orderModel;
      this.model.orderId = order_id;
      this.model.supportTicketCatId = this.category.id;
      this.showCatSelect = false;
    },
    processFile(e) {
      this.model.file = e.target.files[0];
      this.fileName = this.model.file.name;
    },
    processViewedInformation() {
      let store = localStorage.getItem("admin-type");
      let storeId = localStorage.getItem("admin-id");
      storeId = decrypt(storeId)
      store = decrypt(store)
      const obj = {
        administrator: 1,
        calibrator: 1,
        owner: 1,
        client: 1
      }
      if (store.includes("administrator")) {
        obj.administrator = 0;
      } else if (store.includes("calibrator")) {
        obj.calibrator = 0;
      } else if (this.transfer !== undefined && this.transfer !== null && this.transfer !== "") {
        if (this.transfer.fromUser.id === storeId) {
          obj.owner = 0
        } else {
          obj.client = 0
        }
      } else {
        obj.client = 0
      }
      return obj
    }
  }
};
</script>

<style lang="scss">
.fixed-right {
  z-index: 10000;

  i {
    cursor: pointer;
  }

  .card {
    border-radius: 0;
  }

  animation: ease-out;
  width: 20%;
  position: fixed;
  bottom: 0;
  right: 1vw;
  margin: 0;

  .modal-content {
    border-radius: 0;
  }
}

@media only screen and (max-width: 600px) {
  .fixed-right {
    z-index: 10000;

    i {
      cursor: pointer;
    }

    .card {
      border-radius: 0;
    }

    animation: ease-out;
    width: 90%;
    position: fixed;
    bottom: 0;
    right: 1vw;
    margin: 0;

    .modal-content {
      border-radius: 0;
    }
  }
}
</style>

<template>
  <div class="fixed-right" v-if="active">
    <b-card body-class="p-0 pl-3">
      <b-card-header>
        <i class="material-icons pull-right" @click="close">close</i>
        <h5>Support ticket</h5>
      </b-card-header>
      <support-chat ref="chat" :auto-load="false" :show-asign-me="true" :show-head="false"/>
    </b-card>
  </div>
</template>

<script>
import SupportChat from "@/views/dashboard/support/support-chat/index.vue";

export default {
  name: "Floatchat",
  components: {SupportChat},
  data: () => ({
    active: false
  }),
  methods: {
    open(supportId) {
      this.active = true;
      this.$nextTick(() => {
        this.$refs.chat.loadSupport(supportId);
      })
    },
    close() {
      this.active = false;
    }
  },
  mounted() {
    this.$eventBus.$on('chatFloat', supportId => {
      this.open(supportId)
    })
  }
}
</script>

<style scoped lang="scss">
.fixed-right {
  animation: ease-out;
  width: 25%;
  position: fixed;
  bottom: 0;
  right: 1vw;
  margin: 0;
  z-index: 1050;

  i {
    cursor: pointer;
  }

  .card {
    border-radius: 0;
  }
  .modal-content {
    border-radius: 0;
  }
}
</style>
